import React, { useEffect } from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import { initLogo } from "./initLogo"
import css from "./HomeFooter.module.scss"
import { DownloadLink } from "./DownloadLink"

export const HomeFooter = ({ country, isNotFirstEnter }) => {
  const isRu = country === "RU"
  const VkOrInstLink = !isRu ? "https://instagram.com/nmcpstudio" : "https://vk.com/nmcpstudio"

  const links = [
    { logoName: "NMCP", link: "https://nmcpstudio.bandcamp.com/", key: 1 },
    { logoName: "SCRATCH", link: "https://scratch.radio/", key: 2 },
    { logoName: "NMCP_STUDIO", link: `${VkOrInstLink}`, key: 3 },
    { logoName: "CPR", link: "https://cutandpasterecords.com/", key: 4 },
    { logoName: "MASTER", link: "https://www.instagram.com/master_cue/", key: 5 }
  ]

  useEffect(() => {
    links.map((link) => initLogo(link.logoName))
  }, [])

  return (
    <div className={cn(css.outsideWrapper, isNotFirstEnter && css.outsideWrapper__visible)} id="logosWrapper">
      <div className={css.wrapper}>
        {links.map((link) => (
          <a className={css.wrapper_link} href={link.link} target="_blank" rel="noreferrer" key={link.key}>
            <div className={css.wrapper_logoWrapper}>
              <div className={css.wrapper_logo} id={link.logoName}></div>
            </div>
          </a>
        ))}
      </div>
      <DownloadLink />
    </div>
  )
}

HomeFooter.defaultProps = {
  country: "",
  isNotFirstEnter: true
}

HomeFooter.propTypes = {
  country: PropTypes.string,
  isNotFirstEnter: PropTypes.bool
}
