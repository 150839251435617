import React from "react"
import PropTypes from "prop-types"
import css from "./Beats.module.scss"
import cn from "classnames"
import Preloader from "features/Preloader/Preloader"
import useAudioController from "shared/hooks/useAudioController"

export default function BeatCard({ beat, playingBeat, setPlayingBeat, cookie, letter }) {
  const { isLoading, click } = useAudioController({ beat, playingBeat, setPlayingBeat })

  const currentSongIsPlaying = playingBeat && playingBeat === beat.name
  const orderCss = beat.order / 10

  return (
    <button
      type="button"
      className={cn(
        css.beatCard,
        css[`beatCard__${orderCss}`],
        cookie && css[`beatCardWithCookie__${orderCss}`],
        currentSongIsPlaying && css.beatCardActive
      )}
      onClick={click}
    >
      {isLoading && <Preloader />}
      <span className={css.beatCardLetter}>{letter}</span>
    </button>
  )
}

BeatCard.defaultProps = {
  beat: null,
  playingBeat: "",
  setPlayingBeat: null,
  cookie: false
}

BeatCard.propTypes = {
  beat: PropTypes.any,
  playingBeat: PropTypes.string,
  setPlayingBeat: PropTypes.func,
  cookie: PropTypes.bool
}
