import React, { useState, useCallback, useEffect } from "react"

import Cookies from "js-cookie"
import OutsideClickHandler from "react-outside-click-handler"

import useSetCookie from "shared/utils/setCookie"
import getCountry from "shared/utils/getCountry"
import getTime from "shared/utils/getTime"
import useAudioController from "shared/hooks/useAudioController"

import Beats from "./components/Beats/Beats"
import { HomeFooter } from "pages/Home/components/HomeFooter/HomeFooter"
import { beats } from "shared/constants/beats.js"
import { Hand } from "features/Hand/Hand"
import { Blood } from "./components/Blood/Blood"
import { HomeBackground } from "./components/HomeBackground/HomeBackground"
import { Title } from "./components/Title/Title"

export default function Home() {
  const [playingBeat, setPlayingBeat] = useState(null)
  const [isNotFirstEnter, setIsNotFirstEnter] = useState(null)
  const [isDesktop, setIsDesktop] = useState(null)
  const [tabbedLetter, setTabbedLetter] = useState(null)
  const [beat, setBeat] = useState(null)

  const setAgeCookie = getTime()
  const outsideClick = useCallback(() => {
    setPlayingBeat(null)
  })

  const { getIPFromAmazon, country, setCountry } = getCountry()

  isNotFirstEnter === null && setIsNotFirstEnter(Cookies.get("isNotFirstEnter") === "true")

  const countyCookie = Cookies.get("Country")

  useEffect(() => {
    if (!isNotFirstEnter) {
      countyCookie && !country ? setCountry(countyCookie) : getIPFromAmazon()
    }
  }, [isNotFirstEnter])

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsDesktop(true)
    }
    document.getElementById("appContainer").focus()
  }, [isDesktop])
  useSetCookie("isNotFirstEnter", !isNotFirstEnter, setAgeCookie, "true")

  const { keyPress } = useAudioController({ beat, playingBeat, setPlayingBeat })

  const keyPressEvent = (event) => {
    setTabbedLetter(event.key)
    const res = beats.filter((e) => e.letter.toLocaleLowerCase() === event.key)
    if (res.length && beat !== res[0]) {
      setPlayingBeat(null)
      setBeat(res[0])
    }
  }

  useEffect(() => {
    keyPress()
  }, [tabbedLetter, beat])

  return (
    <div tabIndex={0} onKeyDown={keyPressEvent}>
      <HomeBackground isNotFirstEnter={isNotFirstEnter} keyPressEvent={keyPressEvent}>
        <Blood />
        {!isNotFirstEnter && isDesktop && <Hand />}

        <Title isNotFirstEnter={isNotFirstEnter} />
        <OutsideClickHandler onOutsideClick={outsideClick}>
          <Beats
            playingBeat={playingBeat}
            isNotFirstEnter={isNotFirstEnter}
            setPlayingBeat={setPlayingBeat}
            beats={beats}
          />
        </OutsideClickHandler>
        <HomeFooter country={country} isNotFirstEnter={isNotFirstEnter} />
      </HomeBackground>
    </div>
  )
}
