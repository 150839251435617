import Cookies from "js-cookie"
import { useState } from "react"

const useGetCountry = () => {
  const [country, setCountry] = useState()
  let controller = new AbortController()

  async function getIPFromAmazon() {
    try {
      await fetch("https://ipapi.co/json/", { signal: controller.signal })
        .then((d) => d.json())
        .then((d) => {
          Cookies.set("Country", d.country, { expires: 7 })
          setCountry(d.country)
          controller.abort()
        })
    } catch (error) {
      setCountry("RU")
      Cookies.set("Country", "RU", {})
      console.error("Ошибка:", error)
    }
  }
  return { getIPFromAmazon, country, setCountry }
}
export default useGetCountry
