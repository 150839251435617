import { useEffect } from "react"
import Cookies from "js-cookie"

function useSetCookie(name, isAddCookie, setAgeCookie, value) {
  useEffect(() => {
    isAddCookie && Cookies.set(name, value, { expires: setAgeCookie / 1440 })
  }, [])
}

export default useSetCookie
