export const appearanceHand = () => {
  const hand = document.getElementById("hand")
  const fingers = document.getElementById("fingersWrapper")
  const beatsWrapper = document.getElementById("beatsWrapper")
  const globalWrapper = document.getElementById("contentWrapper")
  const logosWrapper = document.getElementById("logosWrapper")
  const blastTitle = document.getElementById("blastTitle")

  if (hand) {
    setTimeout(() => {
      hand.style.opacity = "0"
      fingers.style.opacity = "0"
      globalWrapper.style.background = "rgba(0,0,0,0)"
    }, 5000)
    setTimeout(() => {
      hand.style.display = "none"
      fingers.style.display = "none"
      beatsWrapper.style.opacity = "1"
      logosWrapper.style.opacity = "1"
      blastTitle.style.opacity = "1"
      globalWrapper.style.display = "flex"
    }, 6400)
  }
}
