import { Route, Routes } from "react-router-dom"
import { HOME } from "./routes"
// import Page404 from "pages/page404/Page404"
import Home from "pages/Home/Home"

export default function Router() {
  return (
    <>
      <Routes>
        <Route path={HOME} element={<Home />} />

        {/* service routes */}
        {/* <Route path="*" element={<Page404 />} /> */}
      </Routes>
    </>
  )
}
