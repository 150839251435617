import bloodAnimation from "./bloodAnimation"
import $ from "jquery"
import Chance from "chance"

const chance = new Chance()

const windowWidth = window.innerWidth

export default function animation() {
  if (windowWidth <= 1023) return

  bloodAnimation()
  setTimeout(function () {
    const content = document.getElementById("fingersWrapper")
    const svg = document.getElementById("svgFingers")
    const wrapper = document.getElementById("handWrapper")

    let imageDataArray = []
    const canvasCount = 40

    // get the svg data to make image data URL
    const svgData = new XMLSerializer().serializeToString(svg)
    // base 64 the svg data
    const svg64 = btoa(svgData)

    // base 64 svg data header
    const data64 = "data:image/svg+xml;base64,"

    // prepend the header to svg making image url
    const svgData64 = data64 + svg64

    const image = document.createElement("img")

    // Add the image src
    image.src = svgData64
    setTimeout(() => {
      // create a canvas and render the image there
      const canvas = document.createElement("canvas")

      // get the context and set 400px width and 400px height
      const ctx = canvas.getContext("2d")

      canvas.width = 245
      canvas.height = 660

      // draw the image on to the canvas
      ctx.drawImage(image, 0, 0, 245, 660)

      // Remove the svg from DOM

      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

      let rawPixelArr = imageData.data

      // create more no. of empty imageData equal to this ImageData Length based on Canvas Count
      createBlankImageDatas(imageData)

      // put pixel info to imageDataArray (Weighted Distributed)
      for (let i = 0; i < rawPixelArr.length; i += 4) {
        //find the highest probability canvas the pixel should be in
        let p = Math.floor((i / rawPixelArr.length) * canvasCount)
        let a = imageDataArray[weightedRandomDistrib(p)]
        a[i] = rawPixelArr[i]
        a[i + 1] = rawPixelArr[i + 1]
        a[i + 2] = rawPixelArr[i + 2]
        a[i + 3] = rawPixelArr[i + 3]
      }

      // Create a new canvas with the imageDataArray based on canvas count
      for (let i = 0; i < canvasCount; i++) {
        let canvass = newCanvasFromImageData(imageDataArray[i], canvas.width, canvas.height)
        canvass.classList.add("dust")
        wrapper.appendChild(canvass)
      }

      //apply animation
      $(".dust").each(function (index) {
        setTimeout(() => {
          animateTransform($(this), 100, -100, chance.integer({ min: -25, max: 25 }), 3000, index)
        }, 80 * index)

        // remove the canvas from DOM tree when faded
        $(this).fadeOut(2200 + index * 50, "", () => {
          $(this).remove()
        })
      })
    }, 1)
    setTimeout(() => {
      content.style.transition = "2s"
      content.style.opacity = "0"
    }, 2300)
    setTimeout(() => content.remove(svg), 3800)

    // create empty imageData equal to original ImageData Length
    function createBlankImageDatas(imageData) {
      for (let i = 0; i < canvasCount; i++) {
        let arr = new Uint8ClampedArray(imageData.data)
        for (let j = 0; j < arr.length; j++) {
          arr[j] = 0
        }
        imageDataArray.push(arr)
      }
    }

    function weightedRandomDistrib(peak) {
      var prob = [],
        seq = []
      for (let i = 0; i < canvasCount; i++) {
        prob.push(Math.pow(canvasCount - Math.abs(peak - i), 3))
        seq.push(i)
      }
      return chance.weighted(seq, prob)
    }

    function animateTransform(elem, sx, sy, angle, duration) {
      let td,
        tx,
        ty = 0
      $({ x: 0, y: 0, deg: 0 }).animate(
        { x: sx, y: sy, deg: angle },
        {
          duration: duration,
          step: function (now, fx) {
            if (fx.prop == "x") tx = now
            else if (fx.prop == "y") ty = now
            else if (fx.prop == "deg") td = now
            elem.css({
              transform: "rotate(" + td + "deg)" + "translate(" + tx + "px," + ty * -2 + "px)"
            })
          }
        }
      )
    }

    // Create a new canvas with the imageDataArray
    function newCanvasFromImageData(imageDataArray, w, h) {
      var canvas = document.createElement("canvas")
      canvas.width = w
      canvas.height = h
      let tempCtx = canvas.getContext("2d")
      let tempImageData = new ImageData(imageDataArray, w, h)
      tempCtx.putImageData(tempImageData, 24, 0)
      return canvas
    }
  }, 1000)
}
