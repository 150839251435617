export const beats = [
  { artist: "M.Cue", name: "28", order: 10, letter: "A" },
  { artist: "M.Cue", name: "33", order: 20, letter: "B" },
  { artist: "M.Cue", name: "6", order: 30, letter: "C" },
  { artist: "M.Cue", name: "3", order: 40, letter: "D" },
  { artist: "M.Cue", name: "34", order: 50, letter: "E" },
  { artist: "M.Cue", name: "7", order: 60, letter: "F" },
  { artist: "M.Cue", name: "1", order: 70, letter: "G" },
  { artist: "M.Cue", name: "30", order: 80, letter: "H" },
  { artist: "M.Cue", name: "35", order: 90, letter: "I" },
  { artist: "M.Cue", name: "2", order: 100, letter: "J" },
  { artist: "M.Cue", name: "31", order: 110, letter: "K" },
  { artist: "M.Cue", name: "4", order: 120, letter: "L" }
]
