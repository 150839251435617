import React from "react"
import cn from "classnames"
import css from "./HomeBackgorund.module.scss"

export const HomeBackground = ({ children, isNotFirstEnter }) => {
  return (
    <div className={css.wrapper} tabIndex={0} id="appContainer">
      <div className={css.bottomElipse}></div>
      <div className={css.topRightElipse}></div>
      <div className={css.topLeftElipse}></div>
      <div className={cn(css.backgroundBlood, isNotFirstEnter && css.notFirstTime)} id="backgroundBlood"></div>
      <div className={css.blur} />
      <div className={cn(css.contentWrapper, isNotFirstEnter && css.contentWrapper_noAnimation)} id="contentWrapper">
        <div className={css.noize} />
        <div className={css.content}>{children}</div>
      </div>
    </div>
  )
}
