import React, { useState, useEffect } from "react"
import cn from "classnames"
import BeatCard from "./BeatCard"

import css from "./Beats.module.scss"
import "shared/scss/animations/AnimationKeyFramesTemplate.scss"

export default function Beats({ playingBeat, setPlayingBeat, isNotFirstEnter, beats }) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <div className={cn(css.beatsWrapper, isLoaded && isNotFirstEnter && css.beatsWrapper__visible)} id="beatsWrapper">
      {beats.map((beat) => (
        <BeatCard
          key={beat.order}
          beat={beat}
          playingBeat={playingBeat}
          setPlayingBeat={setPlayingBeat}
          cookie={isNotFirstEnter}
          letter={beat.letter}
        />
      ))}
    </div>
  )
}
