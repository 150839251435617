export default function bloodAnimation() {
  // VARIABLE TO MAKE POSSIBLE NOT REPEATING THE BLOOD SPLASHES
  // let splashesUsed = []

  //FUNCTION TO CHOSE A RANDOM BLOOD SPLASH THAT HAVEN'T BEEN DISPLAYED BEFORE
  const stateBlood = document.getElementById("backgroundBlood")

  function getBlood() {
    stateBlood?.classList.add("animation")
    stateBlood?.setAttribute("data-anim", "true")
    setTimeout(() => {
      Blood3()
    }, 300)
  }

  function Blood3() {
    document.getElementById("splash-3-fade")?.beginElement()
    document.getElementById("splash-3-drip")?.beginElement()
  }
  // getBlood()
  setTimeout(() => getBlood(), 1500)
}
